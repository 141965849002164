import config from 'config'
import * as React from 'react'
import { createSearchHelper } from 'utils/searchClient'

type Product = {
  promoted?: boolean
  _tags: string[]
  imageMediumURL: string
  productPrice: number
  filterColor: string
  productPriceOld?: number | null | ''
  productManufacturerBrand: string
  productName: string
  shippingAndHandling: string
  merchantName: string
  isOnSale: boolean
  salePercentage: number
  wunderSizes: string[]
  productColor: string
  deliveryTime: string
  groupedId?: string | null
  wunderCategories: string[]
  objectID: string
  gender: string
  curated?: boolean
}

export type Result = {
  data: Product
  isFetching: boolean
}

export default function useProducts(ids: string[]) {
  const [data, setData] = React.useState<Product[]>([])
  const [isFetching, setIsFetching] = React.useState(true)

  React.useEffect(() => {
    if (ids.length === 0) {
      setIsFetching(false)
      return
    }
    const helperO = createSearchHelper<Product>(config.indexes.products, {
      disjunctiveFacets: ['objectID'],
    })
    const helperG = createSearchHelper<Product>(config.indexes.products, {
      disjunctiveFacets: ['groupedId'],
    })

    ids.forEach((id) => {
      helperO.addDisjunctiveFacet('objectID', id)
      helperG.addDisjunctiveFacet('groupedId', id)
    })

    Promise.all([helperO.search(), helperG.search()]).then((results) => {
      const allHits = [...results[0].hits, ...results[1].hits]
      const hitDict = {}
      const groupedSet = new Set<string>()
      for (const hit of allHits) {
        if (hit.groupedId && !groupedSet.has(hit.groupedId)) {
          hitDict[hit.objectID] = hit
          groupedSet.add(hit.groupedId)
        }
      }
      setIsFetching(false)
      setData(Object.values(hitDict))
    })
  }, [ids])

  return { data, isFetching }
}
